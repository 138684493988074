@import "@/styles/_mixins.scss"; @import "@/styles/_variables.scss";
.page {
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    margin-top: 20vh;
    @include container;
  }

  &__heading {
    font-size: 70px;
    font-family: var(--font-random-grotesque);
    font-weight: 700;
    margin: 0;
    line-height: 0.9;
    color: $orange;
  }
}
